<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <!--begin::Wrapper-->
  <div class="w-lg-650px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      @submit="onSubmit"
      :validation-schema="schema"
      @invalid-submit="onInvalidSubmit"
    >
      <div class="text-center pb-lg-15">
        <h3>Recupera nome utente</h3>
        <h1 class="blueFit">Federazione Italiana Tennis e Padel</h1>
      </div>
      <div class="mb-5 bg-secondary py-7 px-4 rounded">
        <div class="blueFit fs-5 text-center">
          Hai dimenticato il <strong>Nome utente</strong> che utilizzavi per
          accedere alla prededente vesione del Management FITP?<br /><br />
          inserisci l'indirizzo <strong>e-mail </strong>che utilizzavi per
          accedere alla precedente versione del<strong> Management FITP</strong>
          per recuperare il tuo nome utente!
        </div>
      </div>
      <div class="fv-row mb-10">
        <label class="form-label fs-6 pt-4 fw-bolder text-dark">E-mail</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="email"
          name="mail"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="mail" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center d-flex">
        <router-link
          to="/sign-in"
          class="btn btn-lg btn-light-blueFit fx-bold fs-5 w-100 mb-5 me-5"
        >
          <span class="indicator-label"> Indietro </span>
        </router-link>
        <button
          type="submit"
          id="kt_sign_in_submit"
          class="btn btn-lg bg-blueFIT text-white fx-bold fs-5 w-100 mb-5"
        >
          <span class="indicator-label"> Recupera </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script>
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as yup from "yup";
import { recuperaUsername } from "@/requests/oldLogin";
import { alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
  },
  setup() {
    const router = useRouter();

    const isLoading = ref(false);

    const schema = yup.object().shape({
      mail: yup
        .string()
        .email("Inserire una email valida")
        .required("Obbligatorio"),
    });

    const onSubmit = (values) => {
      isLoading.value = true;
      recuperaUsername(values).then((res) => {
        isLoading.value = false;
        if (res.data.statusCode === 200) {
          Swal.fire({
            html: "Il nome utente è stato recuperato con successo! verrà inviata una e-mail all'indirizzo indicato.",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: "Chiudi",
            allowOutsideClick: false,
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(async (e) => {
            if (e.isConfirmed) {
              router.push(`/sign-in`);
            }
          });
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    return {
      onSubmit,
      schema,
      isLoading,
    };
  },
});
</script>

<style scoped>
i {
  line-height: 1;
  font-size: 1rem;
  color: #0d1660;
}

.bg-blueFIT:focus {
  background: #0d165f !important;
}
</style>
